<template>
    <image-viewer 
        v-if="viewImagesFlag"
        :images="filesForView"
        :documentType = "documentsType"
        @close="viewImagesFlag = false"
    />    
    <alerts-settings
        v-if="showAlertsSettings"
        @close="showAlertsSettings = false"
    />
    <training-viewer 
        v-if="showTrainingViewer && trainingForView"
        :trainingObj="trainingForView"
        @close="showTrainingViewer = false"
    />
    <MultiTrainingViewer 
        v-if="showPlanedTrainingViewer" 
        :trainings="planedTrainingsToShow" 
        :currentTarriffId="defaultCarouselValue"
        :clientTarriffName="clientTariffs[defaultCarouselValue].tarrif_group_name"
        @close="showPlanedTrainingViewer = false" 
        @bookedTrainingShow="e => {bookedTrainingToView = e; showBookedTrainingViewer = true}"
        @planedTrainingShow="e => {showTrainingToBook = true; trainingToBook = e}"    
        @fullTrainingAlert = "e => {showGroupFullfieldView = true; showGroupFullfieldTraining = e}"
        @completedTrainingShow="showCompletedTrainings"
        @missedTrainingShow="showMissedTrainings"
    />
    <CompletedTrainingView v-if="showCompletedTrainingViewer" :trainings="completedTrainingsToShow" @close="showCompletedTrainingViewer = false" />
    <MissedTrainingView v-if="showMissedTrainingViewer" :trainings="missedTrainingsToShow" @close="showMissedTrainingViewer = false" />
    <BookedTrainingView v-if="showBookedTrainingViewer" :training="bookedTrainingToView" @close="showBookedTrainingViewer = false" />
    <PlanedTrainingViewer v-if="showTrainingToBook" :currentTarriffId="defaultCarouselValue" :training="trainingToBook" @close="showTrainingToBook = false" />
    <GroupFullfieldView v-if="showGroupFullfieldView" :training="showGroupFullfieldTraining" @close="showGroupFullfieldView = false" />
    <div v-if="loadingSpinnerFlag" class="bg-[#FFF] fixed w-full h-full z-[120]">
        <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#990D35] border-t-transparent absolute top-[50%] left-[50%] ml-[-20px]"></div>
    </div>
    <div v-else class="bg-[#FFF] pb-6" >
        <student-preview />

        <general-information-bar 
            :trainerName="store.trainerName"
            :studentNumber="store.student.player_number"
            :studentAmplua="store.student.amplua"
            :studentAge="store.studentAge"
            :studentGroup="store.group?.name"
            :studentName="store.studentName"
            :tariffs="clientTariffs"
            :currentTariffId="defaultCarouselValue"
        />
        
        <div class="flex justify-center mt-[58px]">
            <carousel 
                :items-to-show="1.4" 
                class="w-full"
                :navigateTo="defaultCarouselValue"
                :navigationEnabled="true"
                :paginationEnabled="false"    
                v-model="defaultCarouselValue"
            >
                <slide 
                    v-for="(tariff, id) in clientTariffs"
                    :key="id" 
                    :class="{
                        'carousel__slide--active' : id === 1,
                    }"
                >
                    <div>
                        <TariffCard v-if="tariff" class="scale-[0.75]" :tariff="tariff"/>
                        <div v-else class="scale-[0.65]" ></div>
                    </div>
                </slide>

                <!-- <template #addons>
                    <pagination ref="paginationSliderRef" :navigate-to="2" />
                </template> -->
            </carousel>
        </div>

        <div class="text-gray-emb mt-[0] flex justify-between mb-[0px] items-center">
            <div class="text-[9px] tracking-[0.2em]">//////////////</div>
            <div class="text-[13px] font-bold">КАЛЕНДАР ТРЕНУВАНЬ</div>
            <div class="text-[9px] tracking-[0.2em]">//////////////</div>
        </div>

        <training-calendar 
            :key="updateCalendarKey"
            class="mt-[-11px] pt-[0px] scale-[0.95] mb-[22px] origin-top"
            :planedTrainings="planedTrainings"
            :attendedTrainings="attendedTrainings"
            :missedTrainings="missedTrainings"
            :allTrainingsFull="allTrainingsFull"
            
            @attendedTraining="showCompletedTrainings"
            @completedTraining="showCompletedTrainingViewer = true"
            @missedTraining="showMissedTrainings"
            @allDayTrainings="showPlanedTrainings"
            :bookedTrainings="clientBookedTrainings"
        />

        <finance-block 
            :studentIncomes="store.studentOutlays"
        />

        <student-data-block />
        
        <documents-block 
            @showPassportFiles="viewImagesFlag = true; filesForView = store.student.passport_files; documentsType = 'passport_photo'"
            @showIdCodeFiles="viewImagesFlag = true; filesForView = store.student.id_code_files; documentsType = 'id_code_photo'"
            @showAttorneyFiles="viewImagesFlag = true; filesForView = store.student.attorney_child_files; documentsType = 'attorney_photo'"
            @showPhotosForPassport="viewImagesFlag = true; filesForView = [store.student.photo]; documentsType = 'photos_for_passport'"    
        />

        <SquircleComponent
            :smooth="60" 
            :radius="22" 
            :width="screenWidth * 0.92" 
            :height="44" 
            :outlineWidth="1"
            @click="logout"
            class="bg-black-emb text-[#FFF] px-3 flex items-center text-base font-semibold align-middle justify-between mt-[24px] mx-auto"
        >   
            <div class="w-full text-[13px] text-center">Вийти</div>
        </SquircleComponent>
    </div>
</template>

<script>
    import TrainingCalendar from '@/mobileApp/components/TrainingCalendar/TrainingCalendar.vue'
    import StudentPreview from '@/mobileApp/components/StudentPreview/StudentPreview.vue'
    import GeneralInformationBar from '@/mobileApp/components/GeneralInformationBar/GeneralInformationBar.vue'
    import FinanceBlock from '@/mobileApp/components/FinanceBlock/FinanceBlock.vue'
    import StudentDataBlock from '@/mobileApp/components/StudentDataBlock/StudentDataBlock.vue'
    import DocumentsBlock from '@/mobileApp/components/DocumentsBlock/DocumentsBlock.vue'

    import ImageViewer from '@/mobileApp/components/ui/ImageViewer.vue'
    import AlertsSettings from '@/mobileApp/components/AlertsSettings/AlertsSettings.vue'
    import TrainingViewer from '@/mobileApp/components/TrainingViewer/TrainingViewer.vue'
    import TariffCard from './TariffCard.vue'

    import MultiTrainingViewer from '@/mobileApp/components/TrainingViewer/MultiTrainingViewer.vue'
    import CompletedTrainingView from '@/mobileApp/components/TrainingViewer/CompletedTrainingView.vue'
    import MissedTrainingView from '@/mobileApp/components/TrainingViewer/MissedTrainingView.vue'
    import BookedTrainingView from '@/mobileApp/components/TrainingViewer/BookedTrainingView.vue'
    import PlanedTrainingViewer from '@/mobileApp/components/TrainingViewer/PlanedTrainingViewer.vue'

    import GroupFullfieldView from '@/mobileApp/components/TrainingViewer/GroupFullfieldView.vue'

    import SquircleComponent from './SquircleComponent.vue'
    import CorrectedSquircle from '@/mobileApp/components/ui/CorrectedSquircle.vue'

    import { useRouter, useRoute } from 'vue-router'
    import { onMounted, ref, watch } from 'vue'
    import { useMainStore } from '@/mobileApp/store/mainStore'
    import { getAllClientTariffsByClientId, getClientsTrainingByClientId, getAllTariffGroups, getTrainingsByTariffGroupId, countClientsTrainingById, getAllSuperpositionTrainings } from '@/mobileApp/services/apiRequests'
    
    import 'vue3-carousel/dist/carousel.css'
    import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'

    export default {
        name: 'Main',
        setup() {
            const router = useRouter()
            const route = useRoute()
            const store = useMainStore()

            const viewImagesFlag = ref(false)
            const filesForView = ref([])
            const documentsType = ref('')

            const showAlertsSettings = ref(false)

            const loadingSpinnerFlag = ref(true)

            const showTrainingViewer = ref(false)
            const trainingForView = ref({})

            const showPlanedTrainingViewer = ref(false)
            const showCompletedTrainingViewer = ref(false)
            const showMissedTrainingViewer = ref(false)
            const showBookedTrainingViewer = ref(false)
            const showTrainingToBook = ref(false)
            const trainingToBook = ref({})

            const showGroupFullfieldView = ref(false)
            const showGroupFullfieldTraining = ref({})

            const bookedTrainingToView = ref(null)

            const planedTrainingsToShow = ref([])

            const clientTariffs = ref([])

            const defaultCarouselValue = ref(1)

            const updateCalendarKey = ref(0)

            watch(defaultCarouselValue, async () => {
                let currentTarriff = {}

                if (defaultCarouselValue.value === 0) {
                    currentTarriff = clientTariffs.value[1]
                } else if (defaultCarouselValue.value === clientTariffs.value.length - 1) {
                    currentTarriff = clientTariffs.value[clientTariffs.value.length - 2]
                } else {
                    currentTarriff = clientTariffs.value[defaultCarouselValue.value]
                }

                // const allCurrentTariffTrainings = await getTrainingsByTariffGroupId(currentTarriff.tarrif_group_id, new Date().toISOString(), new Date(new Date().getFullYear(), new Date().getMonth() + 2, 0).toISOString())
                // console.log('allCurrentTariffTrainings', allCurrentTariffTrainings)
                // planedTrainings.value.push(...allCurrentTariffTrainings.data)
                
                const superpositions = (await getAllSuperpositionTrainings()).data

                const allSuperpositionIds = superpositions.map(el => el.training_id)
                planedTrainings.value.map(el => {
                    if (allSuperpositionIds.includes(el.training_id)) {
                        el.tariff_group_name = currentTarriff.tarrif_group_name
                        if (el.tariff_group_name.toUpperCase() === 'PERSONAL') {
                            el.max_clients = 1
                        } else {
                            el.max_clients = 3
                        }
                        el.clientsCount = 0
                    }
                    return el
                
                })

                planedTrainings.value = planedTrainings.value.filter(training => training.status === 'planed')

                console.log('currentTarriff.tarrif_group_name.toUpperCase()', currentTarriff.tarrif_group_name.toUpperCase())

                if (currentTarriff.tarrif_group_name.toUpperCase() === 'TEAM') {
                    planedTrainings.value = planedTrainings.value.filter(training => training.superposition == false)
                    // planedTrainings.value.push(...allCurrentTariffTrainings.data)
                } 
            })

            const genInvoce = () => {
                window.open('https://secure.wayforpay.com/page?vkh=63d3cb16-7eb4-488b-834f-1df322d1dba8', '_blank', 'noreferrer')
            }

            const missedTrainings = ref([])
            const attendedTrainings = ref([])
            const planedTrainings = ref([])
            const clientBookedTrainings = ref([])
            const allTrainingsFull = ref([])
            
            const completedTrainingsToShow = ref([])
            const missedTrainingsToShow = ref([])

            const paginationSliderRef = ref(null)

            const screenWidth = ref(window.innerWidth)

            const showPlanedTrainings = (trainings) => {
                showPlanedTrainingViewer.value = true
                console.log('trainings 213123123 324', trainings)
                // .sort((a, b) => a.start_time.localeCompare(b.start_time))
                planedTrainingsToShow.value = trainings
            }

            const showCompletedTrainings = (trainings) => {
                showCompletedTrainingViewer.value = true
                completedTrainingsToShow.value = trainings
            }

            const showMissedTrainings = (trainings) => {
                showMissedTrainingViewer.value = true
                missedTrainingsToShow.value = trainings
                console.log('missedTrainingsToShow', missedTrainingsToShow.value)
            }

            watch(paginationSliderRef, () => {
                //get child
                const paginationSlider = paginationSliderRef.value
                //get all children

                //click on second li element

                // paginationSlider[1].click()
                // console.log('children', document.querySelectorAll('[aria-label="Navigate to slide 2"]'))
            })

            onMounted(async () => {
                try {
                    const studentRes = await store.getStudentData()
                    console.log(studentRes)
                    if (!studentRes) {
                        localStorage.removeItem('access_token')
                        localStorage.removeItem('refresh_token')
                        router.go()
                        return
                    }
                    await store.getGroupData()
                    await store.getTrainerData()
                    await store.getPlanedTrainings()
                    await store.getStudentOutlays()
                    await store.getTelegramBotSubscribed()

                    const superpositions = (await getAllSuperpositionTrainings()).data

                    clientTariffs.value = (await getAllClientTariffsByClientId(store.student.student_id)).data

                    store.clientTariffs = clientTariffs.value

                    clientTariffs.value.push(false)
                    clientTariffs.value.unshift(false)

                    let clientTariffsGroupIds = clientTariffs.value.map(tariff => tariff.tarrif_group_id)
                    let formattedGroupTarriffsIds = []
                    
                    for (let i = 0; i < clientTariffsGroupIds.length; i++) {
                        if (!(formattedGroupTarriffsIds.find(id => id == clientTariffsGroupIds[i]))) {
                            formattedGroupTarriffsIds.push(clientTariffsGroupIds[i])
                        }
                    }

                    clientTariffsGroupIds = formattedGroupTarriffsIds.filter(id => id)

                    const allTariffGroups = (await getAllTariffGroups()).data.map(group => group.group_id)

                    console.log('clientTariffsGroupIds.length', allTariffGroups)

                    if (clientTariffsGroupIds.length == 0) {
                        clientTariffsGroupIds = allTariffGroups
                    }

                    const dateNow = new Date().toISOString()
                    const currentMonthEnd = new Date(new Date().getFullYear(), new Date().getMonth() + 2, 0).toISOString()

                    for (let i = 0; i < clientTariffsGroupIds.length; i++) {
                        if (clientTariffsGroupIds[i]) {
                            const trainings = (await getTrainingsByTariffGroupId(clientTariffsGroupIds[i], dateNow, currentMonthEnd)).data
                            const trainingsWithClientsCount = trainings.map(async training => {
                                // training.clientsCount = (await countClientsTrainingById(training.training_id)).data
                                return training
                            })
                            planedTrainings.value.push(...trainings)
                        }
                    }

                    console.log('superpositions', superpositions)
                    planedTrainings.value.push(...superpositions)

                    // planedTrainings.value = planedTrainings.value.filter(training => training.status === 'planed' || training.status === 'superposition')

                    console.log('planedTrainings', planedTrainings.value)
                    
                    setTimeout(() => {
                        if (!localStorage.getItem('showAlertsSettings')) {
                            showAlertsSettings.value = true
                            localStorage.setItem('showAlertsSettings', false)
                        }

                        // showAlertsSettings.value = true
                    }, 2000)

                    const allClientsBooks = (await getClientsTrainingByClientId(store.student.student_id)).data
                
                    missedTrainings.value = allClientsBooks.filter(book => book.status === 'missing')
                    attendedTrainings.value = allClientsBooks.filter(book => book.status === 'present')
                    clientBookedTrainings.value = allClientsBooks.filter(book => book.status === 'planed')


                    console.log('allClientsBooks', allClientsBooks)

                    window.addEventListener('resize', () => {
                        screenWidth.value = window.innerWidth
                    })


                    // window.addEventListener('focus', () => {
                    //     router.push('/')
                    //     router.go()
                    // })

                    let currentTarriff = {}

                    if (defaultCarouselValue.value === 0) {
                        currentTarriff = clientTariffs.value[1]
                    } else if (defaultCarouselValue.value === clientTariffs.value.length - 1) {
                        currentTarriff = clientTariffs.value[clientTariffs.value.length - 2]
                    } else {
                        currentTarriff = clientTariffs.value[defaultCarouselValue.value]
                    }

                    const superpositions1 = (await getAllSuperpositionTrainings()).data
                    const allSuperpositionIds = superpositions1.map(el => el.training_id)
                    planedTrainings.value.map(el => {
                        if (allSuperpositionIds.includes(el.training_id)) {
                            el.tariff_group_name = currentTarriff.tarrif_group_name
                            if (el.tariff_group_name?.toUpperCase() === 'PERSONAL') {
                                el.max_clients = 1
                            } else {
                                el.max_clients = 4
                            }
                            el.clientsCount = 0
                        }
                        return el

                    })

                    allTrainingsFull.value = planedTrainings.value

                    planedTrainings.value = planedTrainings.value.filter(training => training.status === 'planed')

                    if (clientTariffs.value.length == 0) {
                        //show all trainings in calendar
                        planedTrainings.value = allTrainingsFull.value
                        attendedTrainings.value = allTrainingsFull.value.filter(training => training.status === 'present')
                        missedTrainings.value = allTrainingsFull.value.filter(training => training.status === 'missing')
                    }

                    // console.log('currentTarriff.tarrif_group_name.toUpperCase()', currentTarriff.tarrif_group_name.toUpperCase())

                    if (currentTarriff.tarrif_group_name?.toUpperCase() === 'TEAM') {
                        planedTrainings.value = planedTrainings.value.filter(training => training.superposition == false)
                    } 

                    // if (currentTarriff.tarrif_group_name == undefined) {
                    //     planedTrainings.value = []
                    // }

                    loadingSpinnerFlag.value = false
                } catch {
                    localStorage.removeItem('access_token')
                    localStorage.removeItem('refresh_token')
                    router.go()
                }
            })

            const logout = () => {
                localStorage.removeItem('access_token')
                localStorage.removeItem('refresh_token')
                router.go()
            }

            return {
                router,
                route,
                store,
                logout,
                viewImagesFlag,
                filesForView,
                genInvoce,
                documentsType,
                showAlertsSettings,
                loadingSpinnerFlag,
                showTrainingViewer,
                trainingForView,
                showPlanedTrainingViewer,
                showCompletedTrainingViewer,
                showMissedTrainingViewer,
                clientTariffs,
                missedTrainings,
                attendedTrainings,
                planedTrainings,
                clientBookedTrainings,
                showPlanedTrainings,
                planedTrainingsToShow,
                showBookedTrainingViewer,
                bookedTrainingToView,
                showTrainingToBook,
                trainingToBook,
                completedTrainingsToShow,
                showCompletedTrainings,
                showMissedTrainings,
                missedTrainingsToShow,
                paginationSliderRef,
                defaultCarouselValue,
                screenWidth,
                showGroupFullfieldView,
                showGroupFullfieldTraining,
                allTrainingsFull,
                updateCalendarKey
            }
        },
        components: {
            StudentPreview,
            TrainingCalendar,
            GeneralInformationBar,
            FinanceBlock,
            StudentDataBlock,
            DocumentsBlock,
            ImageViewer,
            AlertsSettings,
            TrainingViewer,
            TariffCard,
            Carousel,
            Slide,
            Pagination,
            Navigation,
            MultiTrainingViewer,
            CompletedTrainingView,
            MissedTrainingView,
            BookedTrainingView,
            PlanedTrainingViewer,
            SquircleComponent,
            GroupFullfieldView,
            CorrectedSquircle
        }
    }
</script>
